@import "src/themes/mojito/styles/index.scss";
.Preview {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include sm {
    padding: 0;
    align-items: center;
  }

  @include lg {
    gap: 20px;
    flex-direction: row;
    padding: space(3) space(3.125) 0;
    padding-bottom: space(3.25);
  }

  &__layout__template {
    display: flex;
    flex: 2;
    align-items: center;
    flex-direction: column;
    @include sm {
      flex-direction: row;
      gap: space(2.25);
    }
  }
  .qr-buttons {
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    width: 95%;
  }
  &__col:nth-child(3) {
    background-color: getvar($colors, "primary", "50");
    padding: space(1.875);
    .button {
      margin: auto;
      @include md {
        margin: 0;
      }
    }

    @include lg {
      padding: 0;
      background-color: white;
    }
  }

  &__buttons__layout__grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    color: getvar($colors, "neutral", "900");
    gap: space(1);
    justify-content: center;
    margin-bottom: space(3);

    @include lg {
      justify-content: flex-start;
      margin-bottom: space(0);
    }

    .qr-home-button {
      height: space(10.25);
      background-color: $home-grid-button-bg-color;
      height: space(4);
      border: $home-grid-button-border-color;
      border-radius: space(0.5);
      display: flex;
      justify-content: flex-end !important;
      width: auto;

      @include lg {
        width: 45%;
      }
      &:focus {
        background-color: white !important;
      }

      img {
        width: space(1.563);
        height: space(1.563);
        z-index: 1;
      }
      .button__text {
        display: none;

        @include lg {
          text-align: left;
          display: block;
          font-size: space(1) !important;
          line-height: space(1.5);
          margin-left: space(1);
        }
      }
      &--icon {
        justify-content: flex-end !important;
      }
      &--bold {
        padding: 0 20px;

        @include lg {
          padding: 12px 30px;
        }
      }
    }
    .button--active .button__icon div:before {
      background-color: $home-grid-button-icon-bg-color;
    }
    .button--icon:focus {
      color: $home-grid-button-icon-focus !important;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      width: 265px;

      @include sm {
        width: 250px;
      }

      @include lg {
        width: 265px;
      }
    }
  }

  &__box {
    h2 {
      display: block;
      @include sm {
        display: none;
      }
    }
  }
  &__content {
    display: flex;
    text-align: center;
    width: 95%;
    margin-top: space(1.5);
    flex-direction: column;
    align-items: center;

    @include sm {
      align-items: flex-start;
      margin-top: 0;
      text-align: left;
      width: 70%;
      padding: space(3.25) space(2.5);
      background-color: $home-grid-content-bg-color;
    }

    h2 {
      display: none;

      @include sm {
        padding: 0;
        display: block;
      }
    }

    p {
      @include lg {
        font-size: space(1.125);
      }
    }
  }
}
