@import "src/themes/mojito/styles/index.scss";
.Hero {
  &__container {
    background-color: $hero-bg-color;
    display: flex;

    @include sm {
      padding-left: space(1);
      padding-right: space(1);
    }

    p,
    ul {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      width: 97%;
      text-align: left;

      .u-br-desktop {
        display: inline;
      }

      @include sm {
        text-align: left;
        .u-br-desktop {
          display: inline;
        }
      }

      @include lg {
        width: 100%;
        text-align: left;
        font-size: 18px;
        font-weight: 400;

        .u-br-desktop {
          display: block;
        }
      }
    }
    ul {
      width: 86%;
      margin-left: 18px;
      text-align: center;
      @include lg {
        width: 100%;
        text-align: left;
        margin-left: 0;
      }
    }

    &--twoColumns {
      display: flex;
      flex-direction: row;
      height: auto;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      @include sm {
        gap: 16px;
        flex-wrap: nowrap;
      }
      @include md {
        gap: 20px;
      }
      @include lg {
        align-items: baseline;
        gap: 30px;
        padding: 0 space(1);
      }

      h1 {
        line-height: 32px !important;
        margin-bottom: 24px !important;

        @include sm {
          text-align: left;
          line-height: 54px !important;
        }
      }
    }
  }
  &__container--reverse {
    flex-direction: row-reverse;
    gap: 0;

    @include sm {
      gap: 34px;
    }

    @include lg {
      gap: 50px;
    }
  }
  &__column {
    &--left {
      text-align: left;
      width: 90%;

      @include sm {
        padding-left: 5rem;
        padding-right: 3rem;
        width: 69%;
      }

      @include md {
        padding-left: 5rem;
        width: 75%;
        padding-right: 1.5rem;
      }

      @include lg {
        width: auto;
        padding-left: 0;
      }

      h1 {
        font-size: space(1.5);
        font-style: normal;
        font-weight: 700;
        line-height: 32px !important;

        @include sm {
          /*     width: 90%; */
          font-size: space(1.125);
          line-height: space(1.5) !important;
        }

        @include lg {
          width: auto;
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 700;
          line-height: 54px !important;
        }

        span {
          font-size: space(1.5);
          font-style: normal;
          font-weight: 700;
          line-height: space(1) !important;

          @include sm {
            font-size: space(1.125);
            line-height: space(1.5);
          }
          @include lg {
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 54px !important;
          }
        }
      }
      .u-br-desktop {
        display: inline;

        @include lg {
          display: block;
        }
      }

      p {
        @include sm {
          /*         width: 85%; */
        }
        @include lg {
          width: auto;
          margin-bottom: space(1.25);
        }
      }

      .cta-hero-banner-btn {
        width: 100%;
        margin-bottom: space(1.125);

        @include sm {
          width: auto;
          margin-bottom: 0;
          margin-top: (0.5);
        }
        @include lg {
          margin-top: (0);
        }
      }
      @include lg {
        width: 50%;

        .u-br-desktop {
          display: block;
        }
      }
    }
    &--right {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      align-self: flex-start;
      width: 98%;

      @include sm {
        width: 87%;
      }

      @include lg {
        margin-top: 0;
        width: 46%;
      }
      img {
        width: 95%;
        height: auto;
        flex-shrink: 0;
        min-width: 84%;
        min-height: 100%;

        @include sm {
          width: 100%;
        }

        @include lg {
          width: 100%;
          max-width: 650px;
          height: auto;
        }
      }
    }

    a.button {
      width: 100%;
      font-size: 18px;
      margin-bottom: 24px !important;

      @include sm {
        width: 71%;
        margin-bottom: 0;
      }
      @include lg {
        width: fit-content;
        margin-bottom: 0;
      }
    }
  }
}

// When the editor is visible, change the layout to column
.Hero__container:has(.qr-editor__container) {
  flex-direction: column;
  align-items: center;
}

.btn_test {
  background-color: green !important;
}

.cta-mobile {
  display: none;
}
